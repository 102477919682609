import LanguageSelect from "@cospex/client/components/LanguageSelect";
import MarketingEmail from "@cospex/client/components/MarketingEmail";
import { useCustomization } from "@cospex/client/hooks/useCustomization";
import useTranslation from "@cospex/client/hooks/useTranslation";
import CompanyImg from "@cospex/client/reverse/img/company-name.png";
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  styled,
} from "@mui/material";
import { useLocation } from "react-router-dom";

import Logo from "./Logo";

const FooterContainer = styled(Box)`
  padding-top: 3rem;
  padding-bottom: 1.5rem;
  text-align: center;
  li {
    margin-bottom: 2rem;
  }
`;

export default function Footer() {
  const { t } = useTranslation();
  const { companyInfoImage, languages } = useCustomization();
  return (
    <Container>
      <Box sx={{ mb: "2rem" }}>
        <Divider light sx={{ mb: 1 }} />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={6}>
          <Logo
            sx={{
              marginBottom: "2rem",
              justifyContent: "flex-start",
            }}
          />
          <Typography
            variant="body2"
            sx={{ maxWidth: 300, py: "1rem", color: "grey.500" }}
          >
            {t("footer-description")}
          </Typography>
          <LanguageSelect options={languages} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ul>
            <li>
              <Link target="_blank" href="/cookies">
                {t("footer-link-cookies")}
              </Link>
            </li>
            <li>
              <Link target="_blank" href="/privacy">
                {t("footer-link-privacy")}
              </Link>
            </li>
            <li>
              <Link target="_blank" href="/terms">
                {t("footer-link-terms")}
              </Link>
            </li>
            <li>
              <Link target="_blank" href="/contact">
                {t("footer-link-contact")}
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <ul>
            <li>
              <Link target="_blank" href="/pricing">
                {t("footer-link-pricing")}
              </Link>
            </li>
            <li>
              <Link target="_blank" href="/refunds">
                {t("footer-link-refunds")}
              </Link>
            </li>
            <li>
              <Link target="_blank" href="/login">
                {t("footer-link-login")}
              </Link>
            </li>
            <li>
              <Link target="_blank" href="/unsubscribe">
                {t("footer-link-unsubscribe")}
              </Link>
            </li>
          </ul>
          <MarketingEmail />
        </Grid>
      </Grid>
      <FooterContainer>
        <Divider light sx={{ mb: 1 }} />
        <Typography variant="body2" color="text.secondary" mb={1.5}>
          Copyright {" © "}
          {new Date().getFullYear()} {__APP_NAME__}{" "}
        </Typography>
        <Box
          component="img"
          sx={{ height: "auto", width: "100%", maxWidth: "240px" }}
          src={companyInfoImage}
        />
      </FooterContainer>
    </Container>
  );
}
